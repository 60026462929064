import './common.js'

$(document).on("turbolinks:load", function() {
  if ($(".ct_pages.ac_homepage")[0]) {
    //Screenshot Gallery
    $(".screenshot-gallery").owlCarousel({
      loop: false,
      items : 3,
      nav : false,
      responsiveClass: true,
      dots: true,
      margin: 15,
      responsive: {
        0: {
          items: 1
        },
        480: {
          items: 1,
          nav: false
        },
        768: {
          items: 2
        },
        992: {
          items: 3,
          nav: false,
          loop: false
        }
      }
    });

    //Testimonial
    $(".testimonial-slider").owlCarousel({
      loop: false,
      items : 1,
      nav : false,
      responsiveClass: true,
      dots: true,
      margin: 15,
      responsive: {
        0: {
          items: 1
        },
        480: {
          items: 1
        },
        768: {
          items: 1
        },
        992: {
          items: 1
        }
      }
    });
  }

  if ($(".ct_icideas.ac_ideate")[0]) {
    $(".input-group-crevate input.form-control").on("focus", function() {
      $(this).parent().addClass("state-focus")  
    });

    $(".input-group-crevate input.form-control").on("focusout", function() {
      $(this).parent().removeClass("state-focus")
    });
  }

  if ($(".ct_ideations.ac_step0")[0]) {
    window._U.check_one = function(cnt) {
      if(cnt>0) {
        $('.wrapper-disable').removeClass("d-none");
        $('#ideation_content').blur();
      } else {
        $('.wrapper-disable').addClass("d-none");
        $('#ideation_content').focus();
      }
    }
  }

  if ($(".ct_ideations.ac_step1.engine-type-text")[0]) {
    window._U.check_rand_limit = function(cnt) {
      if(cnt>=20) {
        $('.wrapper-disable').removeClass("d-none");
        $('#ideation_content').blur();
      } else {
        $('.wrapper-disable').addClass("d-none");
        $('#ideation_content').focus();
      }
    }

    window._U.reset_idea_cnt = function(cnt) {
      $(".cnt-ideas").text(cnt);
    }

    window._U.set_event_idea = function() {
      $(".btn-rnd-ideation").click(function() {
        var t_content = $(this).find(".attr-txt").text();
        var t_id = $(this).data("id");

        if(t_content) {
          $("#ideation_content").val(t_content);
          $("#new_ideation .btn-crevate").trigger('click');
          $("#wrap-rnd-"+t_id).remove();
        }
      });
    }

    window._U.set_event_idea();
  }

  if ($(".ct_ideations.ac_step1.engine-type-card")[0]) {
    // $(".nav-tabs li:first").addClass("active");
    // $(".tab-content div:first").addClass("in active");

    imagesLoaded('#items', function() {
      $('.item').removeClass('d-none');
      
      _U.msnry = new Masonry( '#items', {
        itemSelector : '.item',
        columnWidth : 250,
        isFitWidth: true,
        isAnimated: true
      });
    });



    $("#toggle-idea").click(function() {
      if($(".idea-area").hasClass("closed-idea")) {
        $(".idea-area,.ideas-wrap").removeClass("closed-idea");
      } else {
        $(".idea-area,.ideas-wrap").addClass("closed-idea");
      }
    });
    
    $("#btn-more").click(function() {
      var kid = $(this).data('kid');
      var ien = $(this).data('iengine');
      
      get_cards_page(kid, ien);
    });

    window._U.reset_idea_cnt = function(cnt) {
      $(".cnt-ideas").text(cnt);
    }

    window._U.scroll_bottom = function() {
      $(".card-body").animate({ scrollTop: $(".ideation-panel")[0].scrollHeight}, 1000);
    }

    window._U.append_cards = function( elem ) {
      $("#items").append( elem );

      imagesLoaded('#items', function() {
        $('.item').removeClass('d-none');

        _U.msnry.appended( elem );
        _U.msnry.layout();
      });
      // gridElement.appendChild( elem );
      
      //_U.msnry.append( items ).masonry( 'appended', items );
    }

    function get_cards_page(kid, ien) {
      if(window._U.current_page>=window._U.page_count) {
        alert('마지막 페이지 입니다.')
        $("#btn-more").hide();
      } else {
        $.ajax({
          complete:function(request){},
          data:{"keyword_id": kid, "ids": $("#items-"+window._U.current_page).val()},
          type:'POST',
          dataType: "script",
          url: '/idea/'+ien+'/get_cards_page'
        });
      }
    }

    // function show_idea() {
    //   $(".ideation-panel").stop().animate({
    //     scrollTop: $(".ideation-panel")[0].scrollHeight
    //   }, 800);
    // }

    // function show_ideapanel() {
    //   $(".ideas-wrap").toggleClass("opened" );
    //   $(".idea-area").toggleClass("opened" );
    //   // $container.masonry();
    // }

    _U.scroll_bottom();
  }

  if ($(".ct_ideations.ac_step3")[0]) {
    $("#btn-send-idea, #btn-send-idea2").click(function() {
      if($(".idea-ideacard-selection .btn.selected").length==0) {
        alert("idea로 보낼 아이디어를 선택하세요.");
      } else {
        send_idea();
      }
    });

    var send_idea = function () {
      var kid = $('#keyword_id').val();
      $.ajax({
        complete:function(request){},
        data:{method: "ideacard", keyword: kid},
        type:'POST',
        dataType: "script",
        url: '/idea/send_idea'
      });
    }
  }

  if ($(".ct_ieideas.ac_expand")[0]) {
    //console.log(Popper)
    //console.log(typeof window.$().modal)

    $('#modalCard').modal('show');

    $('#modalCard').on('hidden.bs.modal', function(e) {
      alert('close')
      $(this).removeData('bs.modal');
    });

    $('#modalCard').on('ClassChanged', function(e) {
      console.log('changed class')
    });

    
    
  }

  if ($(".ct_ideas.ac_myideas, .ct_openvotes.ac_open_vote")[0]) {
    window._U.page_loading = false;
    window._U.current_page = 1;
    window._U.last_page = false;

    imagesLoaded('#items2', function() {
      $('.item2').removeClass('d-none');
      
      _U.msnry = new Masonry( '#items2', {
        itemSelector : '.item2',
        columnWidth : 228,
        isFitWidth: true,
        isAnimated: true
      });
    });

    $('#btn-more').on('click', function() {
      _U.get_page($(this).data("qstr"), $(this).data("tag"));
    });

    $('.btn-close-m').on('click', function() {
      $('#modalIdea').hide();
      $('.modal-backdrop').remove();
    });

    $('#filter-keyword').on('click', function() {
      _U.show_keywords();
    });

    $('.page-spinner').hide()


    window._U.get_page = function(q_str="", q_tag="") {
      if(!_U.page_loading&&!_U.last_page) {
        $(".page-spinner").show();
        _U.page_loading = true;

        $.ajax({
          complete:function(request){},
          data:q_str+'&page='+(_U.current_page+1),
          dataType:'script',
          type:'get',
          url: '/myideas/'+(q_tag?"/tag/"+q_tag:"")
        });
      }
    }

    window._U.append_cards = function( elem ) {
      $("#items2").append( elem );

      imagesLoaded('#items2', function() {
        $('.item2').removeClass('d-none');

        _U.msnry.appended( elem );
        _U.msnry.layout();
      });
      // gridElement.appendChild( elem );
      
      //_U.msnry.append( items ).masonry( 'appended', items );
    }

    window._U.show_keywords = function() {
      if($("#keywords").css("display")=="none") {
        $("#tab-keyword a").html('Filter By Tag <i class="fa fa-angle-up"></i>');
        $("#tab-keyword").addClass("active");
        $("#keywords").show();
      } else {
        $("#tab-keyword a").html('Filter By Tag <i class="fa fa-angle-down"></i>');
        $("#tab-keyword").removeClass("active");
        $("#keywords").hide();
      }
    }

  }

  if ($(".ct_ideas.ac_edit")[0]||$(".ct_ideas.ac_new")[0]) {    
    $("#idea_content").summernote({
      lang: 'ko-KR',
      height: 600,
      focus: true,
      prettifyHtml: true,
      callbacks: {
        onImageUpload: function(files){
          console.log('called onImageUpload.')
          for(let i = 0; i < files.length; i++){
            console.log(files[i])
            sendFile(files[i], $(this))
          }
        },
        onMediaDelete: function(target, editor, editiable){
          console.log("called onMediaDelete.")
          let upload_id = target[0].id
          if(!!upload_id){
            deleteFile(upload_id)
          }
          target.remove()
        }
      }
    });

    const sendFile = function(file, toSummernote){
      console.log('called sendFile().')
      let data = new FormData()
      data.append('upload[image]', file)
      $.ajax({
        data: data,
        type: 'POST',
        url: '/uploads.json',
        cache: false,
        contentType: false,
        processData: false,
        success: function(data){
          console.log("image url: ", data.url)
          console.log('successfully created.')
          let img = document.createElement("IMG")
          img.src = data.url
          img.setAttribute('id', data.upload_id)
          toSummernote.summernote("insertNode", img)
        }
      })
    }

    const deleteFile = function(file_id){
      $.ajax({
        type: 'DELETE',
        url: `/uploads/${file_id}`,
        cache: false,
        contentType: false,
        processData: false
      })
    }

    // console.log( window.ckeditor.ui.componentFactory.names() )
  }

  if ($(".ct_ideas.ac_edit")[0]) {
    let cur_template = "";
  
    $(".btn-add-template").click(function() {
      $(".dev-templates").hide();
      cur_template = $(this).attr("dev-template");
      $("#dev-template-"+cur_template).show();
    });

    $(".btn-add-editor").click(function() {
      const thtml = "<br><br><br><br>"+$("#dev-template-"+cur_template).html();
      $("#idea_content").summernote("code", $("#idea_content").summernote("code")+thtml);
      // $(".redactor").redactor("set", $(".redactor").redactor("get")+thtml);
      $("#modalDevlopment").modal("hide");
      $('.modal-backdrop').remove();
    });
  }

  
});